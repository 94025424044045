import { ApolloProvider } from '@apollo/client';
import { AppNavigation } from '@app/navigations';
import * as eva from '@eva-design/eva';
import {
	authAtom,
	AuthAtomInitializer,
	AuthAtomObserver,
	ToastConfig,
	useAppApolloClient,
	useAppearance,
} from '@itrustcapital/ui';
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import appsettings from 'appsettings.json';
import React from 'react';
import { StatusBar, View } from 'react-native';
import { Provider as PaperProvider } from 'react-native-paper';
import Toast from 'react-native-toast-message';
import { RecoilRoot, useRecoilState } from 'recoil';

import packageJson from '../../package.json';
import themeUIDark from '../shared/themes/dark-theme.json'; // <-- Import app theme
import mapping from '../shared/themes/mapping.json'; // <-- Import app mapping
import themeUI from '../shared/themes/theme.json'; // <-- Import app theme

function App() {
	const appearance = useAppearance();
	const [authState, setAuthState] = useRecoilState(authAtom);
	const apolloClient = useAppApolloClient({
		endpoint: appsettings.graphql,
		endpointWS: appsettings.graphqlws,
		clientId: appsettings.clientid,
		scopes: ['openid', 'offline_access', 'iTrustCapitalGateway.Customer'],
		applicationId: 'maestro',
		applicationVersion: packageJson.version,
	});

	React.useEffect(() => {
		AuthAtomInitializer(setAuthState);
	}, []);

	React.useEffect(() => {
		let interval: ReturnType<typeof setInterval> | undefined;

		if (authState.isAuthenticated) {
			AuthAtomObserver(authState);
		}

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [authState.isAuthenticated]);

	return (
		<>
			<IconRegistry icons={EvaIconsPack} />
			<ApplicationProvider
				{...eva}
				customMapping={mapping}
				theme={{ ...(appearance.dark ? themeUIDark : themeUI) }}
			>
				<StatusBar barStyle={appearance.statusBar} translucent={true} />
				<ApolloProvider client={apolloClient}>
					<PaperProvider>
						<AppNavigation />
						<View
							style={{
								position: 'absolute',
								right: 250,
							}}
						>
							<Toast config={ToastConfig} position="top" topOffset={30} />
						</View>
					</PaperProvider>
				</ApolloProvider>
			</ApplicationProvider>
		</>
	);
}

export function AppCore() {
	return (
		<RecoilRoot>
			<App />
		</RecoilRoot>
	);
}
